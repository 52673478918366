*{
  margin: 0;
  padding: 0;
}
.bgimage {
  position: relative;
  height: 100vh; 
  overflow: hidden;
  
}

 .bgimage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(images/LandingBg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  position: relative;
  z-index: 1; /* Ensure that content is above the dimming overlay and background image */
  padding-bottom: 530px; /* If you need additional padding, adjust as necessary */
}
.marquee-container {
  overflow: hidden;
  }
  .nav-link {
    background: linear-gradient(to right, #e9d62b, #8a5403); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .nav-link:hover {
    background: linear-gradient(to right, #8a5403, #e9d62b); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .enroll-link {
    background: linear-gradient(to right, #e9d62b, #8a5403); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .enroll-link:hover {
    color: linear-gradient(to right, #8a5403, #e9d62b); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip:initial;
    color: white;
  }
  .body-button {
    background: linear-gradient(to right, #e9d62b, #8a5403); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip: white;
    color: transparent;
  }
  .body-button:hover {
    color: linear-gradient(to right, #8a5403, #e9d62b); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip:initial;
    color: white;
  }
  .bodyy-button {
    background: linear-gradient(to right, #e9d62b, #8a5403); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip: white;
    color: white;
  }
  .bodyy-button:hover {
    color: linear-gradient(to right, #8a5403, #e9d62b); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip:initial;
    color: white;
  }
  .body-link {
    background: linear-gradient(to right, #e9d62b, #8a5403); /* Purple gradient */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .backgroundcontact{
    background: linear-gradient(to right, #8a5403, #e9d62b);
  }
  .marquee {
  display: flex;
  animation: marquee-animation 10s linear infinite;
  }
  .nav-link {
    position: relative;
    display: inline-block;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #e9d62b, #8a5403); /* Adjust gradient colors as needed */
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .nav-link:hover::after {
    transform: scaleX(1);
  }
  .marquee img {
  flex-shrink: 0;
  /* margin-right: 20px; */
  }
  
  
  /* .circle {
    position: absolute;
    border-radius: 50%;
    width: 400px;
    height: 400px;
  }
  
  .top-right {
    top: 20px;
    right: 20px; /* Adjust this value as needed */
    /* background-color: #20e019;
    
  }
  
  .bottom-left {
    bottom: 20px;
    left: -250px;
    background-color: #20e019;
  }  */
  .slide-up {
    animation: slideUp 1s ease-in-out;
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }
  
  .fade-in-right {
    opacity: 0;
    animation: fadeInRight 1s ease-in-out forwards;
  }
  
  .fade-in-bottom {
    opacity: 0;
    animation: fadeInBottom 1s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fade-in-left {
    opacity: 0;
    animation: fadeInLeft 1s ease-in-out forwards;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  
  @keyframes marquee-animation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
  }
/* Media query for screen sizes less than 750px */
@media (max-width: 750px) {
  .mission{
    margin-left: 55px;
    margin-bottom: 20px;
  }
  .vision{
    margin-left: 55px;
  }
  .formhome{
    padding: 0px 20px;
  }  
  .copyright{
    display: none;
  }
  .linkdesktop{
    display: none;
  }
  .support{
    margin-top: -145px;
    margin-bottom: 30px;
  }
  .rahmat{
    margin-left: 50px;
    margin-bottom: 20px;
  }
  .mosses{
    margin-left: 50px;
    margin-bottom: 20px;
  }
  .mosses1{
    margin-left: 50px;
    margin-bottom: -80px;
  }
  .small{
    display: none;
  }
  .service{
    display: flex;
    justify-content: center;
  }
  .button-menu{
    margin-right: -100px;
    margin-left: 300px;
    margin-top: -25px;
  }
  .total{
    margin-left: -220px;
    padding-left: 50px;
    
  }
  .home-smalll{
    margin-top: 30px;
  }
  .home-small{
    margin-left: 30px;
  }
  .down{
    margin-left: 20px;
    margin-bottom: 40px;
  }
  .total li{
    margin-bottom: 30px;
    margin-left: 20px;
  }
  .menu-slide {
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
  
  .menu-slide.block {
    max-height: 500px; /* Set this value to a height that accommodates your menu items */
  }
  .marquee {
    display: flex;
    animation: marquee-animation 4s linear infinite;
    }
    .partners img{ 
    width: 70px;
    height: 50px;
    }
    .pageimage{
      display: none;
    }
}
